import { IonBadge, IonButton, IonCard, IonCardHeader, IonCol, IonRouterLink, IonRow} from '@ionic/react';
import './SummaryItem.scss';
import { CartItem, CartItemVariation } from 'models';
import ProductCustomTable from "../product-detail/product-custom-table/ProductCustomTable";
import { findItemImage } from "services/catalog-service";
import { getTranslation } from 'translations';
import { useSession } from 'services/useSession';
import CachedImage from 'components/cached-image/CachedImage';

interface SummaryItemProps {
  product: CartItem;
  totalQuantity: number;
  isEditable: boolean;
  setProductToDelete: Function;
}

const SummaryItem: React.FC<SummaryItemProps> = (props) => {
  const { userLanguageCode } = useSession();

  function totalItemQuantity(){
    let total = 0;
    props.product.page_items.forEach(item => {
      total += item.quantity;
    });

    return total;
  }

  function totalItemCost (): string {
    let total = 0.00;
    props.product.page_items.forEach((stock: CartItemVariation) => {
      if(stock.quantity > 0) {
        if(stock.prices) {
          total += (parseFloat(stock.prices.elastic_wholesale) * stock.quantity)
        }
      }
    });

    return total.toFixed(2);
  }

  return (
    <IonCard className='summary-item' key={props.product._id}>
      <IonCardHeader>
        <IonRow>
          <IonCol size='4' className='image-col'>
            <CachedImage key={props.product.color_code + props.product._id} src={findItemImage(props.product, "detail")} />
          </IonCol>
          <IonCol size='8' className='info-col'>
            <h3>{props.product.name}</h3>
            <p className='product-number'>{props.product.product_number}</p>
            {!props.product.isCustomized && <p className='product-number'>{props.product.color_name + " - " + props.product.color_code}</p>}
            {props.product.isCustomized && <IonBadge color={"medium"}>{getTranslation('customized', userLanguageCode)}</IonBadge>}
          </IonCol>
        </IonRow>
        {props.product.isCustomized && props.product.embellishments && 
          <div className='embellishment-display'>
            <ProductCustomTable embellishments={props.product.embellishments.data} />
          </div>
        }
        <IonRow class='summary-row'>
          <IonCol size='6' className='action-buttons'>
            {props.isEditable &&
              <>
                <IonButton color={"secondary"} routerLink={'/product-detail/' + props.product.product_number + "?variationCode=" + props.product.color_code }>{getTranslation('edit', userLanguageCode)}</IonButton>
                <IonRouterLink onClick={() => { props.setProductToDelete({number: props.product.product_number, color: props.product.color_code}); return false; }}>{getTranslation('remove', userLanguageCode)}</IonRouterLink>
              </>
            }       
          </IonCol>
          <IonCol size='6'>
            <div className='info-item'>
              <p className='name'>{getTranslation('totalQty', userLanguageCode)}</p>
              <p className='amount'>{totalItemQuantity()}</p>
            </div>
            <div className='info-item total-price'>
              <p className='name'>{getTranslation('totalPrice', userLanguageCode)}</p>
              <p className='amount'>${totalItemCost()}</p>
            </div>
          </IonCol>
        </IonRow>
      </IonCardHeader>
    </IonCard>
  );
};

export default SummaryItem;
