import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonIcon,
  IonButton
} from '@ionic/react';
import { useRef } from 'react';
import { closeOutline } from  'ionicons/icons';
import { useLocation } from 'react-router-dom';
import './SideMenu.scss';
import { useSession } from '../../services/useSession';
import { useCart } from 'services/useCart';
import { getTranslation } from 'translations';

const SideMenu: React.FC = () => {

  const { logout, userCatalogId, userLanguageCode } = useSession();
  const location = useLocation();
  const { clearOrder } = useCart();

  const myMenu: any = useRef(null);

  const closeMenu = () => {
    myMenu.current.toggle();
  }

  return (
    <IonMenu ref={myMenu} contentId="main" type="overlay" style={{"--width": "100%"}} swipeGesture={false}>
      <IonContent className='side-menu'>
        <div className='close-icon ion-padding'>
          <IonIcon size='large' onClick={closeMenu} icon={closeOutline} color="primary" />
        </div>
        {userCatalogId != "" && 
          <div className="page-header">
            <IonMenuToggle key={'orders'} autoHide={false}>
              <IonButton class="menu-button" shape="round" routerLink={"/explore/catalogs?catalogId=" + userCatalogId}>{getTranslation('shopNow', userLanguageCode)}</IonButton>
            </IonMenuToggle>
          </div>
        }
        <div className="list-container">
          <IonList>
            <IonMenuToggle key={'orders'} autoHide={false}>
              <IonItem className={location.pathname === 'manage/orders' ? 'selected' : ''} routerLink={"/manage/orders"} routerDirection="none" lines="none" detail={false}>
                <div className="large-item">{getTranslation('orders', userLanguageCode)}</div>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle key={'logout'} autoHide={false}>
              <IonItem lines="none" detail={false} onClick={() => { clearOrder(); logout(); }}>
                <IonLabel>{getTranslation('logout', userLanguageCode)}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default SideMenu;
