import { IonIcon, IonText, IonButton, IonItem, IonInput, IonModal, IonHeader, IonToolbar, IonButtons, IonContent } from '@ionic/react';
import { closeOutline, search } from  'ionicons/icons';
import { RefObject, MouseEventHandler, useState, useEffect } from 'react';
import './FacetFilters.scss';
import FacetGroup from './facet-group/FacetGroup'
import { useSession } from 'services/useSession';
import { getTranslation } from 'translations';

interface FacetFiltersProps {
  modalRef: RefObject<HTMLIonModalElement>;
  handleFilterClose: MouseEventHandler;
  availibleFacets: any[];
  toggleFacet: Function;
  applyFacets: Function;
  selectedFacets: string[];
  clearFilters: Function;
  searchTerm: string;
  setSearchKeyword: Function;
}

const FacetFilters: React.FC<FacetFiltersProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState<string>(props.searchTerm);
  const { userLanguageCode } = useSession();

  useEffect(() => {    
    setSearchTerm(props.searchTerm)
  }, [props.searchTerm]);

  function filterDisplayText () {
    return (props.selectedFacets.length > 0 || props.searchTerm != "") ? "(" + (props.selectedFacets.length + (props.searchTerm != "" ? 1 : 0)) + ")" : "";
  }

  return (
    <IonModal className='pwa-modal sort' ref={props.modalRef} initialBreakpoint={.9}>
      <IonHeader className="no-border-toolbar">
        <IonToolbar mode='ios'>
          <h2>{getTranslation('filterBy', userLanguageCode)}</h2>
          <IonButtons slot="end">
            <IonButton onClick={props.handleFilterClose} ><IonIcon class="icon" size="large" icon={closeOutline} color="primary" /></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='filter-action-sheet facet-action-sheet'>
          <div className='search-field'>
            <IonItem>
              <IonInput placeholder={getTranslation('searchProducts', userLanguageCode)} onIonChange={(e: any) => setSearchTerm(e.detail.value)} value={searchTerm}></IonInput>
            </IonItem>
            <IonIcon onClick={() => {props.applyFacets(searchTerm)}} icon={search} />
          </div>
          <div className='filter-options'>
            {props.availibleFacets && props.availibleFacets.map((facet: any, i: number) => {
              return (
                <FacetGroup key={i} facet={facet} toggleFacet={props.toggleFacet} selectedFacets={props.selectedFacets} />
              );
            })}
          </div>
          <div className='filter-actions'>
            <IonButton onClick={() => {props.clearFilters()}} color={"secondary"} shape="round" >
              <IonText slot='start'>{getTranslation('clearFilters', userLanguageCode)} {filterDisplayText()}</IonText>
            </IonButton>
            <IonButton disabled={props.selectedFacets.length == 0 && searchTerm == ""} onClick={() => {props.applyFacets(searchTerm)}} color={"primary"} shape="round" >
              <IonText slot='start'>{getTranslation('applyFilters', userLanguageCode)}</IonText>
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default FacetFilters;