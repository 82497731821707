import { IonBreadcrumb, IonBreadcrumbs } from "@ionic/react";
import { useEffect, useState } from "react";
import "./Breadcrumbs.scss";
import { useLocation, useHistory } from "react-router-dom";
import { getProduct } from "services/catalog-service";
import { User } from "models/User";
import { useSession } from "services/useSession";

interface BreadcrumbsProps {
  pageName?: string;
  skipLast?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState<any[]>([]);

  const { getUserInfo } = useSession();


  useEffect(() => {
    generateBreadcrumbs();
  }, [history, props.pageName]);

  useEffect(() => {
    return history.listen(() => {
      generateBreadcrumbs();
    });
  }, [history]);

  const mappedNames = [
    {
      routeName: "product-listing",
      displayName: "Catalogs",
    },
    {
      routeName: "manage",
      displayName: "Manage",
    },
    {
      routeName: "catalogs",
      displayName: "Catalogs",
    },
    {
      routeName: "explore",
      displayName: "Explore",
    },
    {
      routeName: "cart",
      displayName: "Shopping Cart",
    },
    {
      routeName: "orders",
      displayName: "Orders",
    },
    {
      routeName: "order-detail",
      displayName: "Order Details",
    },
    {
      routeName: "product-detail",
      displayName: "Product",
    },
  ];

  async function generateBreadcrumbs(){
    const asPathWithoutQuery = location.pathname.split("?")[0];
    let asPathNestedRoutes = asPathWithoutQuery.split("/").filter((v) => v.length > 1);
    let breadcrumbs: any[] = [];

    for (let index = 0; index < (asPathNestedRoutes.length - (props.skipLast ? 1 : 0)); index++) {
      let breadcrumb = await processBreadcrumb(asPathNestedRoutes, asPathNestedRoutes[index], index, false);
      if(breadcrumb.title == "Product" || breadcrumb.title == "Shopping Cart") {
        const result: User | undefined = await getUserInfo();

        let catalogBreadcrumb =  {href: "", title: ""}
        if(result && result.customer && result.customer.name){
          catalogBreadcrumb.title = result.customer.name;
        }
        if(result && result.catalogs && result.catalogs.length > 0) {
          catalogBreadcrumb.href = "/explore/catalogs?catalogId=" + result.catalogs[0].key;
        }

        if(catalogBreadcrumb.href != "" && catalogBreadcrumb.title != "") {
          breadcrumbs.push(catalogBreadcrumb)
        }

        if(breadcrumb.title == "Shopping Cart") {
          breadcrumb.href = "";
          breadcrumbs.push(breadcrumb)
        }
      } else if(breadcrumb.title == "Order Details") {
        breadcrumb.href = "";
        breadcrumbs.push(breadcrumb)
      } else {
        breadcrumbs.push(breadcrumb)
      }
    }

    if(props.pageName) {
      breadcrumbs.push(await processBreadcrumb(asPathNestedRoutes, props.pageName, asPathNestedRoutes.length, true))
    }

    setBreadcrumbRoutes(breadcrumbs);
  }

  async function getProductData(id: string) {
    let foundProduct: any = await getProduct(id);
    if(foundProduct.name)
      return foundProduct.name;
    else
      return id;
  }

  async function processBreadcrumb(splitString: string[], breadcrumb: string, index: number, notLink: boolean): Promise<any> {
    let breadcrumbTitle = breadcrumb;
    let hrefString = "/" + breadcrumb;
    
    if (index > 0) {
      hrefString = "/" + splitString[index - 1] + "/" + breadcrumb;
      breadcrumbTitle = await getProductData(breadcrumb);
    }

    mappedNames.forEach((mapping: any) => {
      if (breadcrumb == mapping.routeName) {
        breadcrumbTitle = mapping.displayName;
      }
    });

    if(notLink) {
      return {title: breadcrumbTitle, href: ""}  
    }

    return {href: hrefString, title: breadcrumbTitle}
  }

  return (
    <IonBreadcrumbs>
      {breadcrumbRoutes.map((breadcrumb: any, index: number) => {
        return (
          <IonBreadcrumb onClick={() => { if(breadcrumb.href == null) { console.log("going back"); history.goBack(); } }} disabled={breadcrumb.href == ""} key={index} routerLink={breadcrumb.href ?? undefined} separator={(breadcrumbRoutes.length - 1) != index}>
            <div className="breadcrumb-text">
              {breadcrumb.title}
            </div>
          </IonBreadcrumb>
        );
      })}
    </IonBreadcrumbs>
  );
};

export default Breadcrumbs;
