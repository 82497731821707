import { API, graphqlOperation } from 'aws-amplify';
import { exploreDynamic } from '../graphql/queries';
import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage-service';
import constants from '../constants';
import { getJwt } from './auth-service';

export async function getUserDashboard(custumer_number: string, online: boolean) {
    const storedValue = await getStoredKey(constants.EXPLORE_SLIDES);
    
    if(shouldUseCachedValue(storedValue, online, true)){
        return storedValue;
    } else {
        const jwt = await getJwt();
        const result = await API.graphql(graphqlOperation(exploreDynamic, {client: constants.CURRENT_CLIENT_NAME, customer_number: custumer_number, jwt: jwt}));
        await setStoredKey(constants.EXPLORE_SLIDES, result)
        return result;
    }
}