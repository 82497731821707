import React, { createContext, useState, useEffect } from 'react';
import { User } from '../models/User';
import { authUser, cognitoLogout, getJwt, getSession, refreshSession } from "../services/auth-service";
import { clearStorage, getStoredKey, setStoredKey } from './storage-service';
import constants from '../constants';
import { getUser } from './user-service';

export const SessionContext = createContext<{
  error: string | undefined;
  isAuthenticated: boolean;
  authValidated: boolean;
  userCatalogId: string;
  userLanguageCode: string;
  getUserInfo: (userFreshData?: boolean) => Promise<User | undefined>;
  login: (username: string, password: string, remember: boolean) => Promise<boolean>;
  logout: () => Promise<void>;
}>({
  error: undefined,
  isAuthenticated: false,
  authValidated: false,
  userCatalogId: "",
  userLanguageCode: "",
  getUserInfo: async () => {
    throw new Error('Method not implemented');
  },
  login: async () => {
    throw new Error('Method not implemented');
  },
  logout: async () => {
    throw new Error('Method not implemented');
  },
});

export const SessionProvider: React.FC<any> = ({ children }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authValidated, setAuthValidated] = useState<boolean>(false);
  const [userCatalogId, setUserCatalogId] = useState<string>("");
  const [userLanguageCode, setUserLanguageCode] = useState<string>("en");

  useEffect(() => {
    checkAuthenticationStatus();
  }, []);

  const checkAuthenticationStatus = async () => {

    try {
      let session = await getSession();
      
      if (session && session?.isValid()) {
        setIsAuthenticated(true);
        setError('');
      } else {
        console.log("trying refresh")
        await refreshSession();
        session = await getSession();

        if (session && session?.isValid()) {
          console.log("refresh worked")
          setIsAuthenticated(true);
          setError('');
        }
      }

      await setAuthValidated(true);
    } catch (catchError: any) {
      setAuthValidated(true);
      setIsAuthenticated(true);
      setError('');
    }
  };

  const getUserInfo = async (userFreshData: boolean = false): Promise<User | undefined> => {

    let jwt = await getJwt();

    const userDetails = userFreshData ? await getUser(jwt, true) : await getStoredKey(constants.USER_INFO);

    if (!userDetails) return;

    if(userDetails && userDetails.validateDynamic.user && userDetails.validateDynamic.user.catalogs && userDetails.validateDynamic.user.catalogs[0]){
      await setUserCatalogId(userDetails.validateDynamic.user.catalogs[0].key);
    }

    return userDetails.validateDynamic.user;
  };

  const login = async (username: string, password: string, remember: boolean): Promise<boolean> => {
    try {
      const authenticationData = {
        username: username,
        password: password,
      };
      let attempt = await authUser(authenticationData, remember, true);
      if(attempt) {
        setUserLanguageCode(await getStoredKey(constants.USER_LANGUAGE_CODE))
        setError('');
        setIsAuthenticated(true);
        return true
      } else {
        return false
      }
    } catch (catchError: any) {
      setIsAuthenticated(false);
      setError(catchError.toString());

      return false;
    }
  };

  const logout = async (): Promise<void> => {
    try {

      await cognitoLogout();
      
      let savedUsername = await getStoredKey(constants.STORED_USERNAME);
      let savedPassword = await getStoredKey(constants.STORED_PASSWORD);
      
      await clearStorage();

      if(savedUsername && savedPassword) {
        setStoredKey(constants.STORED_PASSWORD, savedPassword)
        setStoredKey(constants.STORED_USERNAME, savedUsername);
      }
      setError('');
      setIsAuthenticated(false);
    } catch (catchError: any) {
      console.log("ERROR WITH LOGOUT", error)
      setError(catchError.toString());
    }
  };

  return (
    <SessionContext.Provider
      value={{
        error,
        isAuthenticated,
        authValidated,
        userCatalogId,
        userLanguageCode,
        getUserInfo,
        login,
        logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};