import { useContext } from 'react';
import { CartContext } from './CartContext';

export const useCart = () => {
  const { cartItems, totalCartItems, currentOrder, removeCartItem, createOrder, showModifiedError,
          addByProductIDAndCode, stockQuantityAlreadyInCart, clearOrder, anyCurrentEmbellishments, 
          changeDropShipAddress, dropShipAddress, loadExistingOrder, remainingCredits, updateRemainingCredits } =
    useContext(CartContext);

  if (CartContext === undefined) throw new Error('useCart must be used within a CartProvider');

  return { cartItems, totalCartItems, currentOrder, removeCartItem, createOrder, showModifiedError,
           addByProductIDAndCode, stockQuantityAlreadyInCart, clearOrder, anyCurrentEmbellishments, 
           changeDropShipAddress, dropShipAddress, loadExistingOrder, remainingCredits, updateRemainingCredits };
};