import { IonItem, IonLabel, IonText, IonIcon, IonBadge } from '@ionic/react';
import { chevronForwardOutline } from  'ionicons/icons';
import { ReadOnlyOrder } from 'models';
import { getBadgeType, getBadgeText } from 'services/order-service';
import './OrderItem.scss';
import moment from "moment"
import { getTranslation } from 'translations';
import { useSession } from 'services/useSession';

interface OrderItemProps {
  order: ReadOnlyOrder;
  openOrder: Function;
}

const OrderItem: React.FC<OrderItemProps> = (props) => {
  const { userLanguageCode } = useSession();

  return (
    <>
        {props.order.state == "final_submission" && 
          <IonItem routerLink={"/manage/orders/order-detail/" + props.order._id} className={'order'} >
            <IonLabel>
              <h3>{props.order.name}</h3>
              <p>{getTranslation('order', userLanguageCode)} #{props.order.number}</p>
              <IonText><IonBadge color={getBadgeType(props.order.state)}>{getBadgeText(props.order.state)}</IonBadge> <span>{getTranslation('lasSaved', userLanguageCode)} { moment(props.order.updated_at).format('MM/DD/YYYY')}</span></IonText>
            </IonLabel>
            <IonText slot="end">
              <p>{props.order.total_quantity} {getTranslation('items', userLanguageCode)}</p>
              <p className='price'>${parseFloat(props.order.total_prices.elastic_wholesale).toFixed(2)}</p>
            </IonText>
            <IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
          </IonItem>
        }
        {props.order.state != "final_submission" && 
          <IonItem className={'order'} onClick={() => { props.openOrder(props.order) }} >
            <IonLabel>
              <h3>{props.order.name}</h3>
              <p>{getTranslation('order', userLanguageCode)} #{props.order.number}</p>
              <IonText><IonBadge color={getBadgeType(props.order.state)}>{getBadgeText(props.order.state)}</IonBadge> <span>{getTranslation('lasSaved', userLanguageCode)} { moment(props.order.updated_at).format('MM/DD/YYYY')}</span></IonText>
            </IonLabel>
            <IonText slot="end">
              <p>{props.order.total_quantity} {getTranslation('items', userLanguageCode)}</p>
              <p className='price'>${parseFloat(props.order.total_prices.elastic_wholesale).toFixed(2)}</p>
            </IonText>
            <IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
          </IonItem>
        }
    </>
  );
};

export default OrderItem;
