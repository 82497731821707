import { IonIcon } from '@ionic/react';
import { removeOutline, addOutline } from  'ionicons/icons';
import { useState } from 'react';
import FacetCheckbox from "./facet-checkbox/FacetCheckbox"

interface FacetGroupProps {
  facet: any;
  toggleFacet: Function;
  selectedFacets: any[];
}

const FacetGroup: React.FC<FacetGroupProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className='facet-group'>
      <h3 onClick={() => setOpen(!open)}>{props.facet.title} {open ? <IonIcon icon={removeOutline} color="primary" /> : <IonIcon icon={addOutline} color="primary" />}</h3>
      <div className={'options' + (open ? "expanded" : "")}>
        {props.facet.options && props.facet.options.map((term: any, i: number) => {
          return (
            <FacetCheckbox key={i} option={props.facet} term={term} toggleFacet={props.toggleFacet} selectedFacets={props.selectedFacets} />
          );
        })}
      </div>
    </div>
  );
};

export default FacetGroup;