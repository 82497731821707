import { IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';

interface ProductTitleProps {
  product: any;
}

const ProductTitle: React.FC<ProductTitleProps> = (props) => {
  return (
    <div className="product-title-area">
      <IonItem lines="none">
      <div className="product-title">
          {" "}
          <div className="ion-text-wrap">{props.product.name} </div>
        </div>
      </IonItem>
      <div className="item-details">
        <IonGrid>
          <IonRow>
            <IonCol size='4'>
             <p className='product-number'>{props.product.number}</p>
            </IonCol>
            <IonCol size='8'>
             <p className='product-price'><span>Price</span><strong> {props.product.priceRangeToShow}</strong></p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default ProductTitle;
