import { API, graphqlOperation } from 'aws-amplify'
import constants from '../constants';
import { brandDynamic, clientDynamic, forgotPasswordDynamic, resetPasswordDynamic } from '../graphql/queries'
import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage-service'
import { AuthUserData } from "../models/auth-user-types";
import { getUser } from './user-service'
import {
    AuthenticationDetails,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUserPool,
    CognitoUserSession
} from "amazon-cognito-identity-js";
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export async function getBrandConfig(type: string, online: boolean) {
    const storedValue = await getStoredKey(constants.BRAND + type);
    
    if(shouldUseCachedValue(storedValue, online, true)){
        return storedValue;
    } else {
        const result: any = await API.graphql(graphqlOperation(brandDynamic, {type: type, client: constants.CURRENT_CLIENT_NAME}));
        await setStoredKey(constants.BRAND + type, result.data)
        return result.data;
    }
}

export async function sendForgotPasswordEmail(email: string): Promise<any> {
    let body = {
        user: {
            email: email
        }
    }
    return await API.graphql(graphqlOperation(forgotPasswordDynamic, {body: body, client: constants.CURRENT_CLIENT_NAME}));
}

export async function changePassword(token: string, password: string): Promise<any> {
    let body = {
        user: {
            password: password,
            password_confirmation: password,
            reset_password_token: token
        }
    }
    return await API.graphql(graphqlOperation(resetPasswordDynamic, {body: body, client: constants.CURRENT_CLIENT_NAME}));
}

export async function getClientConfig(clientName: string) {
    const storedValue = await getStoredKey(constants.CLIENT + clientName);
    
    if(storedValue){
        return storedValue;
    } else {
        const result: any = await API.graphql(graphqlOperation(clientDynamic, {client: clientName}));
        await setStoredKey(constants.CLIENT + clientName, result.data)
        return result.data;
    }
}

const authorize = (authenticationDetails: AuthenticationDetails, pool: CognitoUserPool): Promise<CognitoUserSession> => {
    const userData = {
        Username: authenticationDetails.getUsername(),
        Pool: pool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser?.authenticateUser(authenticationDetails, {
            onSuccess: (session: CognitoUserSession) => {
                console.log("session", session)
                resolve(session);
            },
            onFailure: (error: any) => {
                console.log(error)
                reject(error) 
            },
        });
    })
}

export async function authUser(params: AuthUserData, remember: boolean, online: boolean) {

    try{
        const pool = getCognitoPool();

        const {username, password} = params

        const authenticationData = {
            Username: username,
            Password: password,
        };

        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );

        const session = await authorize(authenticationDetails, pool)
        await setUserLoggedInDetails(session, remember, online);

        return true
    } catch (error: any){
        console.log("error", error)
        return false;
    }
}

async function setUserLoggedInDetails(authDetails: CognitoUserSession, remember: boolean, online: boolean) {
    const id_token: CognitoIdToken = authDetails.getIdToken();
    const refresh_token = authDetails.getRefreshToken().getToken();

    if(remember){
        setStoredKey(constants.REFERESH_TOKEN, refresh_token)
    }

    // TODO: For Phase 2/3 figure out where to get user language code from
    setStoredKey(constants.USER_LANGUAGE_CODE, 'en')

    await getUser(id_token.getJwtToken(), online);
}

const getCognitoUser = () => {
    let cognitoPool = getCognitoPool();

    return cognitoPool?.getCurrentUser();
}

const getCognitoPool = () => {
    return new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: process.env.REACT_APP_COGNITO_REG_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_REG_CLIENT_ID
    });
}

export const getSession = async () => {
    const cognitoUser = getCognitoUser()

    return cognitoUser?.getSession((error: Error, result: CognitoUserSession | null) => {
        if (error) {
            console.error('Error getting session', JSON.stringify(error));
        }
        return result;
    });

};

export const cognitoLogout = async () => {
    const cognitoUser = getCognitoUser();
    await cognitoUser?.signOut();

    return '/login';
}

export const refreshSession = async () => {
    const cognitoUser = getCognitoUser();
    const refreshToken = await getStoredKey(constants.REFERESH_TOKEN);
    if(!refreshToken) {
        return false;
    }

    return new Promise((resolve, reject) => {
        const cognitoRefreshToken = new CognitoRefreshToken({RefreshToken: refreshToken});

        cognitoUser?.refreshSession(cognitoRefreshToken, (error: Error, result: CognitoUserSession | null) => {
            if (error) {
                console.error('Error refreshing token', JSON.stringify(error));
                reject(error);
            }

            resolve(result?.getAccessToken()?.getJwtToken());
        })
    })
}

export const getJwt = async () => {
    let session = await getSession();

    try {
      if (session && session?.isValid()) {
        return session.idToken.jwtToken;
      } else {
        console.log("trying refresh")
        await refreshSession();
        session = await getSession();

        if (session && session?.isValid()) {
          console.log("refresh worked")
          return session.idToken.jwtToken;
        }
      }

    } catch (catchError: any) {
      alert("Please logout and log back in to continue.")
    }
  };