import { IonContent, IonPage } from "@ionic/react";
import SearchBox from "components/search-box/SearchBox";

const Search: React.FC = () => {

  return (
    <IonPage className="page-with-menu search-page">
      <IonContent>

        <SearchBox />

      </IonContent>
    </IonPage>
  );
};

export default Search;
