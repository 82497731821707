import { API, graphqlOperation } from 'aws-amplify';
import { validateDynamic } from '../graphql/queries';
import { setStoredKey } from './storage-service';
import constants from '../constants';

export async function getUser(id_token: string, online: boolean) {
    if(online) {
        const result: any = await API.graphql(graphqlOperation(validateDynamic, {client: constants.CURRENT_CLIENT_NAME, ct: id_token}));
        await setStoredKey(constants.USER_INFO, result.data)
        return result.data;
    } else {
        alert("You must have an internet connection to perform this action.")
        return false;
    }
}